import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data, location }) => {
  const news = data.news.edges
  const heroImage = data.heroImage
  const products = data.products.edges
  const strategy = data.strategy.edges
  const legal = data.legal.edges
  const events = data.events.edges  

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title="TeamBLUE - Sitemap"
          description="Use this sitemap to find the content you're looking for and then navigate your way around the TeamBLUE website."
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="TeamBLUE sitemap"
          subtitle=""
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="8">
              <MDBRow>
                <MDBCol md="6" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Home
                      <Link to="/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                  </h2>
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Company
                      <Link to="/about/" className="ml-2 linkedin">
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <p className="font-w-400 text-medium">
                      <Link to="/about/" className="effect-noline">
                        About us
                      </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link to="/contact/" className="effect-noline">
                        Contact us
                      </Link>
                    </p>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      UNICOM Global Divisions
                      <Link to="/unicom-global/divisions/" className="ml-2 linkedin" >
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                    </h2>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Search
                      <Link to="/search/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>                  
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Services
                      <Link to="/services/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                    </h2>
                    <p className="font-w-400 text-medium">
                      <Link to="/services/support/" className="effect-noline"> Support </Link>
                    </p>
                    <p className="font-w-400 text-medium">
                      <Link to="/powerhouse-crf/" className="effect-noline"> PowerHouse customer registration form  </Link>
                    </p>
                       
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                      Privacy &amp; legal
                    </h2>
                    {legal.map(({ node }, index) => (
                      <p className="font-w-400 text-medium" key={index}>
                        <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                      </p>
                    ))}                  
                
                </MDBCol>

                <MDBCol md="6" className="mb-5">
                <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                    Newsroom
                    <Link to="/news/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  {news.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={`/news/${node.slug}/`} className="effect-noline"> {properCase(node.title)} </Link>
                    </p>
                  ))}
                    <ul className="fa-ul text-medium ml-4">
                      <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                          <Link to="/news/" className="effect-noline">More news</Link>
                      </li>
                    </ul>   
                  
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md="4" className="mb-5">
            <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
              Events
              <Link to="/events/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
            </h2>
            {events.map(({ node }, index) => (
              <p className="font-w-400 text-medium" key={index}>
                <Link to={`/events/${node.slug}/`} className="effect-noline"> {properCase(node.title)} </Link>
              </p>
            ))}
            <ul className="fa-ul text-medium ml-4">
              <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                  <Link to="/events/" className="effect-noline">More events</Link>
              </li>
            </ul> 
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default Sitemap 

export const query = graphql`
  query SitemapQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    products: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "products" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    strategy: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "strategy" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "about" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    news: allContentfulNews(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.unicomsi.com" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    events: allContentfulEvent(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.unicomsi.com" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }     
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
